import { TopTracksModel, TracksModel, TrackModel, TopTrackModel } from "../../../../models";
import styles from './index.module.sass'

interface TrackDisplayProperties {
    trackModel: TracksModel | undefined;
    topModel: TopTracksModel | undefined;
    title: string;
}

const TrackDisplay = (props: TrackDisplayProperties) => {
    return(
        <div className={styles.container}>
            <div className={styles.header}>
                <h2>{props.title}</h2>
            </div>
            <div className={styles.tracks}>
                <div className={styles.trackContainer}>
                    {props.trackModel !== undefined && (
                        <>
                            {props.trackModel.tracks.map((track: TrackModel, index: number) => (
                                <div className={styles.track} key={index}>
                                    <img src={track.album.images[0].url} alt="Album cover." />
                                    <a className={styles.cover} href={track.uri}>
                                        <p>&#8226; {track.name} &#8226;</p>
                                        <p>&#8226; {track.artists[0].name} &#8226;</p>
                                    </a>
                                </div>
                            ))}
                        </>
                    )}
                    {props.topModel !== undefined && (
                        <>
                            {props.topModel.items.map((track: TopTrackModel, index: number) => (
                                <div className={styles.track} key={index}>
                                    <img src={track.album.images[0].url} alt="Album cover." />
                                    <a className={styles.cover} href={track.uri}>
                                        <p>&#8226; {track.name} &#8226;</p>
                                        <p>&#8226; {track.artists[0].name} &#8226;</p>
                                    </a>
                                </div>
                            ))}
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default TrackDisplay;