import axios from "axios";
import { useState } from "react";
import { TokenModel, TopTracksModel, TracksModel, UserModel } from "../../models";
import { SavePlaylist, TrackDisplay } from "./components";
import styles from './index.module.sass';

interface RecommendationProperties {
    authed: boolean;
    user: UserModel;
    top: TopTracksModel;
    tracks: TracksModel;
    token: TokenModel;
    api: string;
    headers: any;
}

const Recommendations = (props: RecommendationProperties) => {
    const [page, setPage] = useState(1);
    const Clamp = (toClamp: number, min: number, max: number) => Math.max(Math.min(toClamp, Math.max(min, max)), Math.min(min, max));

    const Prev = () => {
        setPage(Clamp(page - 1, 1, 3));
    }

    const Next = () => {
        setPage(Clamp(page + 1, 1, 3));
    }

    const Save = (title: string) => {
        const uris: string[] = [];
        
        props.tracks.tracks.forEach((item) => {
            uris.push(item.uri)
        });

        const body: {} = {
            name: title,
            public: true,
            collaborative: false,
            description: 'Made with ❤ by https://spot.emilis.co.uk'
        };

        axios.post(`${props.api}/users/${props.user.id}/playlists`, body, props.headers)
        .then(result => {
            axios.post(`${props.api}/playlists/${result.data.id}/tracks?uris=${uris.join(',')}`, {}, props.headers)
            .catch(error => console.log(error))
        }, error => console.log(error));
    }

    return(
        <div className={styles.container}>
            <div className={styles.auth}>
                <div className={styles.skipper}>
                    <div className={styles.button} onClick={() => Prev()}>&lt;</div>
                    <p>{page}</p>
                    <div className={styles.button} onClick={() => Next()}>&gt;</div>
                </div>

                {page === 1 && <TrackDisplay topModel={props.top} trackModel={undefined} title={`${props.user.display_name.split(' ')[0]}, you liked these songs...`} />}
                {page === 2 && <TrackDisplay topModel={undefined} trackModel={props.tracks} title="So we think you'll like these!" />}
                {page === 3 && <SavePlaylist save={Save} />}
            </div>
        </div>
    )
}

export default Recommendations;