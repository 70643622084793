import React, { useState } from 'react';
import styles from './index.module.sass';
import background from '../../../../resources/images/background.png'
import foreground from '../../../../resources/images/foreground.png'

interface SavePlaylistProperties {
    save: (title: string) => void;
}

const SavePlaylist = (props: SavePlaylistProperties) => {
    const [saved, setSaved] = useState(false);
    const [title, setTitle] = useState("");
    const [error, setError] = useState(true);


    const Save = (e: React.MouseEvent) => {
        e.preventDefault();

        setSaved(true);
        props.save(title);
    }

    const TitleHandler = (value: string) => {
        setTitle(value);

        if(value.trim() === "") {
            setError(true);
        } else setError(false);
    }

    return(
        <div className={styles.container}>
            <h2>Want to save your new playlist?</h2>

            <form>
                <input type="text" placeholder="Playlist Name" value={title} onChange={(e) => TitleHandler(e.target.value)} />
                <button onClick={Save} disabled={error || saved}>{saved ? 'Saved' : 'Save'}</button>
            </form>

            <img src={background} alt="" />
            <img src={foreground} alt="" />
        </div>
    )
}

export default SavePlaylist;