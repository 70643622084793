import { useRef, useState } from "react";
import foreground from '../../resources/images/foreground.png';
import background from '../../resources/images/background.png';
import styles from './index.module.sass';
import { Link } from "react-router-dom";

interface HomeProperties {
    login: () => void;
    authed: boolean;
}

const Home = (props: HomeProperties) => {
    const [scroll, setScroll] = useState(0);
    const containerRef = useRef<HTMLDivElement>(null);
    
    /**
     * Handle Scroll Event.
     */
     const ScrollHandler = (e: React.UIEvent<HTMLDivElement>) => {
        setScroll(containerRef.current ? containerRef.current?.scrollTop : 0);
    }

    /**
     * Login Handler.
     * @param e Mouse Event.
     */
    const Login = (e: React.MouseEvent) => {
        e.preventDefault();

        props.login();
    }

    return(
        <div className={styles.container} onScroll={ScrollHandler} ref={containerRef}>
            <header>
                <div style={{top: `calc(50% - ${scroll*0.2}px)`}}>
                    <h1>SPOT</h1>
                    <h2>The smart way to find new music.</h2>
                </div>
                <img style={{top: `${scroll*0.15}px`}} src={background} alt="" />
                <img style={{top: `${scroll*0.2}px`}} src={foreground} alt="" />
            </header>
            <section>
                <div className={styles.sectionend}>
                    <h1>How it works</h1>
                </div>
                <div className={styles.sectioncentre}>
                    <div>
                        <h1>1.</h1>
                        <p>Login with Spotify.</p>
                    </div>
                    <div>
                        <h1>2.</h1>
                        <p>We check your top artists and songs.</p>
                    </div>
                    <div>
                        <h1>3.</h1>
                        <p>We curate a new playlist for you with 50 new songs for you to try.</p>
                    </div>
                    <div>
                        <h1>4.</h1>
                        <p>View our selected songs in your browser.</p>
                    </div>
                    <div>
                        <h1>5.</h1>
                        <p>Save the paylist to your Spotify account.</p>
                    </div>
                </div>
                <div className={styles.sectionend}>
                    {props.authed ? <Link to="/recommendations"><button>See Recommendations!</button></Link> : <button onClick={Login}>Get started!</button>}
                </div>
                <img style={{top: `${(scroll-1000)*0.15}px`}} src={background} alt="" />
                <img style={{top: `${(scroll-1000)*0.2}px`}} src={foreground} alt="" />
            </section>
        </div>
    );
}

export default Home;