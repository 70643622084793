import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import './resources/styles/index.module.sass';

import styles from './index.module.sass'
import { useState, useEffect } from 'react';
import { TokenModel, UserModel, TopTracksModel, TopArtistModel, RecommendationModel, TracksModel } from './models';
import { Home, Recommendations } from './components';

const ISDEV: boolean = false;
const API: string = ISDEV ? 'http://localhost:4923' : '/api';
const SPOTIFYAPI: string = 'https://api.spotify.com/v1';

const Application = () => {
    const location = useLocation();
    const history = useHistory();

    const [headers, setHeaders] = useState<{} | undefined>(undefined);
    const [token, setToken] = useState<TokenModel | undefined>(undefined);
    const [authed, setAuthed] = useState(false);
    const [user, setUser] = useState<UserModel | undefined>(undefined);
    const [topTracks, setTopTracks] = useState<TopTracksModel | undefined>(undefined);
    const [topArtist, setTopArtist] = useState<TopArtistModel | undefined>(undefined);
    const [recommendations, setRecommendations] = useState<RecommendationModel | undefined>(undefined);
    const [tracks, setTracks] = useState<TracksModel | undefined>(undefined);

    /**
     * Get Token.
     */
    useEffect(() => {
        const searchParameters = new URLSearchParams(location.search);
        
        if(searchParameters.has('code')) {
            axios.post<TokenModel>(`${API}/api/token`, {code: searchParameters.get('code')})
            .then(result => {
                setToken(result.data);
                setHeaders({headers: {'Authorization': `Bearer ${result.data.access_token}`}});
                setAuthed(true);
            }, error => {
                console.log(`Error getting token: ${error}`);
            })
            .finally(() => {
                searchParameters.delete("code"); 
                history.replace({search: searchParameters.toString()});
            })
        }
    }, [location, history]);

    /**
     * Get User Information and Top Tracks.
     */
    useEffect(() => {
        if(headers !== undefined && token !== undefined) {
            axios.get<UserModel>(`${SPOTIFYAPI}/me`, headers)
            .then(result => setUser(result.data), error => console.log(error));

            axios.get<TopTracksModel>(`${SPOTIFYAPI}/me/top/tracks?time_range=short_term&limit=50&offset=0`, headers)
            .then(result => {setTopTracks(result.data);}, error => console.log(error));
        }
    }, [token, headers]);

    /**
     * Get Top Artist.
     */
    useEffect(() => {
        if(topTracks !== undefined && headers !== undefined) {
            axios.get<TopArtistModel>(`${SPOTIFYAPI}/artists/${topTracks.items[0].artists[0].id}`, headers)
            .then(result => setTopArtist(result.data), error => console.log(error));
        }
    }, [topTracks, headers]);

    /**
     * Get Recommendation Information.
     */
    useEffect(() => {
        if(topArtist !== undefined && topTracks !== undefined && headers !== undefined) {
            let artists: string[] = [];
            let tracks: string[] = [];

            topTracks.items.forEach((item) => {
                artists.push(item.artists[0].id);
                tracks.push(item.id);
            })

            // Adding the artist IDs changes the results and I'm not sure if I like them yet.
            // const artistIDs: string = artists.slice(0, 1).join(",");
            const trackIDs: string = tracks.slice(0, 4).join(",");

            axios.get(`${SPOTIFYAPI}/recommendations?limit=50&seed_tracks=${trackIDs}`, headers)
            .then(result => setRecommendations(result.data), error => console.log(error))
        }
    }, [topArtist, topTracks, headers])

    /**
     * Get Track List from Recommendations.
     */
    useEffect(() => {
        if(recommendations !== undefined && headers !== undefined) {
            const recommended: string[] = [];
    
            recommendations.tracks.forEach((item) => {
                recommended.push(item.id);
            });

            const ids = recommended.join(',');

            axios.get<TracksModel>(`${SPOTIFYAPI}/tracks?ids=${ids}`, headers)
            .then(result => setTracks(result.data), error => console.log(error))
            .finally(() => {
                history.push('/recommendations');
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recommendations, headers])

    /**
     * Login Handler.
     * @param e Mouse Event.
     */
    const Login = () => {
        axios.get(`${API}/auth`)
        .then(result => window.location = result.data.redirect)
    }

    return(
        <div className={styles.container}>
            <Switch>
                <Route exact path="/"><Home login={Login} authed={authed}/></Route>
                <Route exact path="/recommendations">{authed === true && headers !== undefined && token !== undefined && tracks !== undefined && user !== undefined && topTracks !== undefined && (
                    <Recommendations headers={headers} api={SPOTIFYAPI} token={token} tracks={tracks} top={topTracks} user={user} authed={authed} />
                )}</Route>
            </Switch>
        </div>
    );
};

ReactDOM.render((
    <Router>
        <Application />
    </Router>
), document.getElementById('root'));